<template>
  <section id="body">
    <button id="contactbtn" @click="scrollButton()">Contact me</button>
    <h1>SEAN WAKKER</h1>
    <h5>freelancer / student at hogeschool Leiden</h5>
    <img id="img" src="@/assets/pf.jpg" alt="foto of sean">
    <p>Sean is a passionate 20 year old student for Hogeschool Leiden who is set on fulfilling his dreams of becoming his own boss and having fun while doing so. He lives a daily life of coding, enjoying music, having fun with friends, shopping with his girlfriend and much more. </p>
    <br>
    <h2>MY LIFE</h2>
    <div id="alinea" v-motion-fade-visible-once>
      <h3>What do I do?</h3>
      <p>As mentioned up above, I'm a big dreamer and I'm trying my best to be a hard worker too. Since the start of August, I've been getting serious about setting up routines and schedules. The goal? To build up some discipline. I'm hitting the gym regularly, working on my website and some other projects, all while making sure I've got enough time carved out for school stuff (because, you know, school comes first, obviously).</p>
      <br>
      <p>Speaking of the gym, I'm putting in the sweat there to challenge myself and get better. It's not just about getting fit, but it's also teaching me to stick to things and not give up easily.
      </p><br>
      <p>Then there's this website I'm working on. It's like my creative playground where I'm putting together all my interests and skills. Whether it's the design or the code behind it, each bit is a piece of my dedication to making something cool.
      </p>
      <p>Apart from that, I've got a bunch of other projects going on. These aren't just something to do – they're my way of exploring ideas and trying to make them real.
      </p>
      <p>And of course, school is a big deal too. I'm not letting all this other stuff get in the way of my studies. Getting educated is still at the top of my list.
      </p><br>
      <p>So, there you have it. I'm in this phase of going all in – pushing myself, managing my time, and chasing my ambitions. Every new day is a chance to get closer to what I want, all while handling my current responsibilities too.</p></div>
    <img id="img" class="sara" src="@/assets/Whatsapp.jpg" alt="foto of sean">
    <br>
    <!-- <h2>WHAT DO I OFFER?</h2>
    <h3>MY EXPERIENCE</h3>
      <experience-slider></experience-slider>
      <div id="alinea" v-motion-fade-visible-once>
        <h3>My skills</h3>
        <ul>
          <li><h4>HTML: </h4><p>Because of my specialisation in web design I am well versed in the HTML language</p></li>
          <li><h4>CSS: </h4><p></p></li>
          <li><h4>JavaScript: </h4><p></p></li>
          <li><h4>Vue.js: </h4><p></p></li>
          <li><h4>Laravel(in progress): </h4><p></p></li>
          <li><h4>Linux (in progress): </h4><p></p></li>
          <li><h4>SQL (in progress): </h4><p></p></li>
          <p>I mostly use firebase as host and database but am open te learning new things</p>
        </ul>
    </div> 
     <div id="alinea" v-motion-fade-visible-once>
        <h3>Order of action</h3>
      <p> 1. Contact
      </p>
      <p> 2. Discussing of Preferences
      </p>
      <p> 3. Producing of first sketch
      </p>
      <p> 4. Building
      </p>
      <p> 5. Presenting of first build
      </p>
      <p> 6. Adding/Removing client side preferences
      </p>
      <p> 7. Presenting final product
      </p>
    </div> -->
    <h2 id="contactform" v-motion-pop-visible>CONTACT</h2>
    <p>If you have a project or collaboration in mind, please feel free to get in touch with me. I'd love to discuss how my skills and expertise can contribute to your web development needs.</p>
    <contact-card ref="contactcard" v-motion-fade-visible-once></contact-card>
    <br>
    <footer>website by sean wakker @2023</footer>
  </section>
</template>



<script>
// import ExperienceSlider from "../cards/ExperienceSlider.vue";
import ContactCard from "../cards/ContactCard.vue";
// import SkillSliders from "../cards/SkillSliders.vue";

export default {
  data() {
    return {};
  },
  components: {
    ContactCard,
    // SkillSliders,
    // ExperienceSlider,
  },
  methods:  {
    scrollButton() {
      const element = document.getElementById("contactform");
      element.scrollIntoView({behavior: 'smooth' });
    }
  },

};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Notable&family=Poppins&display=swap');

#body {
  max-width: 50%;
  margin: auto;
}

li{
  list-style-type: none;
}

h4{
  color: white;
  margin-left: 1.5vw;
  
}

img{
  max-width: 40%;
  box-shadow: 10px 10px white;
  margin: 20px;
  animation: ImgAnimation 1.25s forwards;
  position: none;
}


.sara{
  max-width: 60%;
}

#skills{
  margin-left: 20px;
}


#contactbtn {
  background-color: white; /* Set the button background color to white */
  color: var(--primary);
  font-size: 16px;
  border: 2px solid var(--primary); /* Add a border */
  padding: 10px 20px; /* Add more padding */
  position: fixed; /* Make the button fixed */
  bottom: 40px; /* Distance from the bottom */
  right: 40px; /* Distance from the right */
  z-index: 999; /* Ensure the button appears above other content */
  cursor: pointer; /* Change cursor to a pointer when hovering */
  transition: background-color 0.3s, color 0.3s; /* Add a smooth transition effect */
  font-family: 'Poppins', serif;
}

#contactbtn:hover {
  background-color: var(--primary); /* Change background color on hover */
  color: white; /* Change text color on hover */
}

#alinea{
  background-color: var(--secondary);
  margin: 10px;
  padding-bottom: 10px;
}

footer{
  color: white;
  font-style: italic;
  text-align: right;
}

.highlighted-title {
  text-shadow: 2.5px 2.5px 0px #eb452b,
    5px 5px 0px #efa032,
    7.5px 7.5px 0px #46b59b,
    10px 10px 0px #017e7f;
  padding: 5px;
}

@keyframes ImgAnimation {
  0% {
    box-shadow: 0px 0px white;
    translate: 10px 10px;
  }
  20% {
    box-shadow: 5px 5px white;
    translate: 5px 5px;
  }
  100% {
    box-shadow: 10px 10px white;
    translate: 0px 0px;
  }
}

@keyframes ImgHover {
  0% {
    box-shadow: 10px 10px white;
    translate: 0px 0px;
  }
  100% {
    box-shadow: 12px 12px white;
    translate: -2px -2px;
  }
}

@media only screen and (max-width: 1200px) {
  #body{
    max-width: none;
    margin: 0;
    }
}

</style>