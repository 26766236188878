<template>
  <section>
    <header>
      <nav-bar></nav-bar>
    </header>
    <main>
    <router-view v-slot="slotProps">
        <component :is="slotProps.Component"></component>
      </router-view>
    </main>
  </section>
</template>

<script>
import NavBar from './components/navigation/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Poppins&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Anton&family=Cinzel&family=Notable&family=Poppins&display=swap');
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h1{
    font-family: 'Anton', sans-serif;
    font-size: 350%;
    color: white;
  }
  
  h5{
    font-size: 150%;
    color: var(--secondary);
  }

  h2 {
    font-family: 'Notable', sans-serif;
    color: white;
    padding: 10px;
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
    font-family: 'Notable', sans-serif;
    color: white;
    padding: 10px; 
  }

  :root {
    --primary: #1C1C1C  ;
    --primarylight: #2e2e2e  ;
    --secondary: #567C5A  ;
    --textcolor: #E5E5E5    ;
    --tertiary:  #b53c3c   ;
    --quaternary: #39533c  ;
  }

  #app {
    font-family: 'Poppins', 'Cinzel', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary);
  }

  p{
    color: var(--textcolor);
    padding-left: 1vw;
    padding-right: 2vw;
  }


</style>
