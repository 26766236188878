<template>
  <section>
    <nav class="navbar">
      <ul class="navbar-list">
        <li class="navbar-item"><img id="logo" src="@/assets/logo.png" alt="Logo"></li>
        <li class="navbar-item"><a href="https://www.instagram.com/seanwakker/"><img id="insta" src="@/assets/instagram.png" alt="Logo"></a></li>
        <li class="navbar-item"><a href="https://www.linkedin.com/in/sean-wakker-777103177?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><img id="insta" src="@/assets/linkedin2.png" alt="Logo"></a></li>
      </ul>
    </nav>
    <div id="progressbar">
      <div id="progressindicator" :style="{ width: progressWidth }"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      progressWidth: "0%",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const maxScroll = documentHeight - windowHeight;
      const scrolled = window.scrollY;

      this.progressWidth = `${(scrolled / maxScroll) * 100}%`;
    },

    scrollTo() {
      this.$refs.contactcard.scrollIntoView({ behavior: 'smooth' });
    }
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Notable&family=Poppins&display=swap');

section {
  position: fixed;
  z-index: 1;
}

.navbar {
  background-color: var(--secondary);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 10px 20px;
}

.navbar-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  padding: 8px;
  cursor: pointer;
  transition: color 0.3s;
}

#logo {
  max-width: 25px;
}

#insta {
  max-width: 30px;
  margin-left: 1vw;
  border-radius: 2px;
}

#contactbtn {
  background-color: var(--primary);
  border: none;
  color: var(--textcolor);
  font-size: 16px;
  border-radius: 5px;
}
#contactbtn.navbar-item {
  margin-left: auto;
}

#progressbar {
  background-color: var(--secondary);
  /* outline: 2px solid black; */
  height: 0.5vh;
  width: 100%;
  position: absolute;
  bottom: 0;
}

#progressindicator {
  background-color: var(--textcolor);
  height: 100%;
}
</style>