import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { MotionPlugin } from '@vueuse/motion'
import App from './App.vue';

//Components
import HomePage from './components/pages/HomePage.vue';

const router = createRouter({
    history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/homepage' },
    { path: '/homepage', component: HomePage },
  ],
  linkActiveClass: 'active',
})

const app = createApp(App);

router.isReady().then(function(){
    app.mount('#app');
});

app.use(router);
app.use(MotionPlugin)

