<template>
  <section id="backgroundCard">
    <form id="contactForm" @submit.prevent="commitInput">
      <div class="form-control">
        <label for="name">Your Name / Company name</label>
        <input id="name" name="name" v-model="name" type="text" required />
      </div>
      <div class="form-control">
        <label for="email">Your Email address</label>
        <input id="email" name="email" v-model="email" type="email" required />
      </div>
      <div class="form-control">
        <label for="website">Your Current website (Optional)</label>
        <input id="website" name="website" v-model="website" type="text" />
      </div>
      <div class="form-control">
        <label for="other">Other info you'd like to share</label>
        <input id="other" name="other" v-model="other" type="text" />
      </div>
      <div class="form-control">
        <label>What service are you looking for?</label>
        <div>
          <input id="service-new" name="service" type="checkbox" v-model="service" value="a new website" />
          <label for="service-new">A new website</label>
        </div>
        <div>
          <input id="service-finetune" name="service" type="checkbox" v-model="service" value="website finetuning" />
          <label for="service-finetune">Website finetuning</label>
        </div>
        <div>
          <input id="service-nothing" name="service" type="checkbox" v-model="service" value="not sure yet" />
          <label for="service-nothing">Else / I'm not sure yet...</label>
        </div>
      </div>
      <div>
        <input type="submit" value="Send" form="contactForm" />
      </div>
    </form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      website: '',
      service: [],
      other: ''
    };
  },
  methods: {
    async commitInput() {
      if (!this.name || !this.email) {
        alert('Name and email are required fields.');
        return;
      }

      console.log(this.name + ',' + this.email + ',' + this.website + ',' + this.service )
      fetch(`https://portfolio-a776f-default-rtdb.europe-west1.firebasedatabase.app/opdrachten.json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          website: this.website,
          service: this.service,
          other: this.other
        }),
      });

      this.name = '';
      this.email = '';
      this.website = '';
      this.service = '';
      this.other = '';
    },
    logReferrer(referrer){
      this.referrer = referrer;
    }
  }
}
</script>


<style scoped>

form {
margin: 2rem auto;
max-width: 40rem;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
padding: 2rem;
background-color: var(--secondary);
}

label{
  color: var(--textcolor);
}

.form-control {
margin: 0.5rem 0;
}

input,
select {
display: block;
width: 100%;
font: inherit;
margin-top: 0.5rem;
background-color: var(--textcolor);
color: var(--primary);
outline: none;
border:none;
padding: 5px;
}

input:focus,
select:focus {
background-color: white;
}

select {
width: auto;
}

input[type='checkbox'],
input[type='radio'] {
display: inline-block;
width: auto;
margin-right: 1rem;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
font-weight: normal;
}

button {
font: inherit;
border: 2px solid var(--textcolor);
background-color: var(--primary);
color: var(--textcolor);
cursor: pointer;
padding: 0.75rem 2rem;
border-radius: 30px;
}

button:hover,
button:active {
background-color: #002350;
}

</style>